import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import WsStaticImage from '@/components/common/WsStaticImage';
import TopPicksMobile from '../top-picks-mobile';
import DeliveryMobile from '../delivery-mobile';

interface BannerMobileProps {
  items?: any;
}
const BannerMobile: React.FC<BannerMobileProps> = ({ items }) => {
  return (
    <>
      <TopPicksMobile />
      {/* <section className={styles.storeinner}> */}
      <div className="anniva-mobile-slider">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {items?.items?.map((banner: any, i: number) => (
            <SwiperSlide key={i}>
              <Link href={banner.href} className={styles.storeimg}>
                <figure>
                  <WsStaticImage
                    alt={banner.alt}
                    src={banner.image}
                    title={banner.title}
                    width={375}
                    height={225}
                    loading={i <= 1 ? 'eager' : 'lazy'}
                    quality={100}
                    sizes="100vw"
                    unoptimized={false}
                  />
                </figure>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* </section> */}
      <section className={styles.discountmobilesection}>
        <div className={styles.discountinner}>
          <ul className={styles.discounslider}>
            <li className={styles.discountimg}>
              <WsStaticImage
                src={'/images/home-new1/no-cost-emi.png'}
                alt="Emi Installation"
                width={596}
                height={132}
                quality={100}
                loading="eager"
              />
            </li>
            <li className={styles.discountimg}>
              <WsStaticImage
                src={'/images/home-new1/store-strip.png'}
                alt="Emi Installation"
                width={596}
                height={132}
                quality={100}
                loading="eager"
              />
            </li>
            <li className={styles.discountimg}>
              <WsStaticImage
                src={'/images/home-new1/free-delivery-strip.png'}
                alt="Emi Installation"
                width={596}
                height={132}
                quality={100}
                loading="eager"
              />
            </li>
          </ul>
        </div>
      </section>
      <DeliveryMobile />
    </>
  );
};

export default BannerMobile;
