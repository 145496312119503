import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import WsStaticImage from '@/components/common/WsStaticImage';
// import Image from 'next/image';
import { useSelector } from 'react-redux';
import { getRequestedSettings } from '@/store/features/commonSlice';
import { getSettingByKeyValue, updateImageVersion } from '@/utils/common';
import ShopRoomsMobile from '../shop-rooms-mobile';

const Description = dynamic(
  () => import('../../mobile/finest-brand-mobile/description'),
  { ssr: false }
);

interface FinestBrandMobileProps {
  items: any;
}

const FinestBrandMobile: React.FC<FinestBrandMobileProps> = ({ items }) => {
  const postersData = [
    {
      href: 'deal-of-the-day',
      ariaLabel: 'Bedroom Furniture',
      imgSrc: '/images/home-new1/diwali-special-banner.jpg',
      alt: 'buy wooden furniture in India',
      title: 'New Launches',
    },
  ];

  const requestedSettings = useSelector(getRequestedSettings);
  const imgVer = getSettingByKeyValue(requestedSettings, 'config_imgvrsn');
  items.items = updateImageVersion(items?.items, 'v', imgVer);

  return (
    <>
      <section className={styles.finestbrandmobsection}>
        <div className={styles.finestbrandmobinner}>
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.finestbrandheading}>
                <div className={styles.headingNew}>
                  India's Finest <h1>Online Furniture</h1> Brand
                </div>

                <Description description={items?.description} />
              </div>
            </div>
          </div>
          <div className={styles.finestbrandcontent}>
            <div className="wdRow">
              {postersData.map((poster, index) => (
                <div className="wdCol-lg-12" key={index}>
                  <Link
                    href={poster.href}
                    className={styles.finestbrandcard}
                    aria-label={poster.ariaLabel}
                  >
                    <div className={styles.finestbrandimg}>
                      <WsStaticImage
                        src={`${poster.imgSrc}?v=${imgVer}`}
                        alt={poster.alt ?? 'image'}
                        title={poster.title}
                        width={585}
                        height={508}
                        quality={75}
                      />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <ShopRoomsMobile />
    </>
  );
};

export default FinestBrandMobile;
