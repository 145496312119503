import React from 'react';
import styles from './style.module.scss';
// import Image from 'next/image';
import Link from 'next/link';
import WsStaticImage from '@/components/common/WsStaticImage';
// import Image from 'next/image';
// import { useSelector } from 'react-redux';
// import { getSettingByKeyValue } from '@/utils/common';
// import { getRequestedSettings } from '@/store/features/commonSlice';

const ShopRoomsMobile: React.FC = () => {
  // const requestedSettings = useSelector(getRequestedSettings);
  // const imgVer = getSettingByKeyValue(requestedSettings, 'config_imgvrsn');\
  const data = {
    component: 'shop-rooms',
    title: 'Shop By Rooms',
    subtitle: 'Perfect Furniture with Our Selection!',
    items: [
      [
        {
          href: 'living-room-furniture',
          imageSrc: '/images/home-new1/shoproom1.webp',
          name: 'Living Room',
        },
        {
          href: 'bedroom-furniture',
          imageSrc: '/images/home-new1/shoproom2.webp',
          name: 'Bedroom',
        },
        {
          href: 'study-room-furniture',
          imageSrc: '/images/home-new1/shoproom3.webp',
          name: 'Study Room',
        },
      ],
      [
        {
          href: 'dining-room-furniture',
          imageSrc: '/images/home-new1/shoproom4.webp',
          name: 'Dining & Kitchen',
        },
        {
          href: 'kids-furniture',
          imageSrc: '/images/home-new1/shoproom5.webp',
          name: 'Kids Room',
        },
        {
          href: 'outdoor-furniture',
          imageSrc: '/images/home-new1/shoproom6.webp',
          name: 'Outdoor',
        },
      ],
    ],
  };

  return (
    <>
      <section className={styles.shoproomsection}>
        <div className="wdRow ">
          <div className="wdCol-lg-12">
            <div className={styles.finestbrandheading}>
              <p className={styles['title']}>{data?.title}</p>
              <p className={styles['sub-title']}>{data?.subtitle}</p>
            </div>
          </div>
        </div>
        <div className={styles.shoproomcontent}>
          {data?.items?.map((category: any, i: number) => {
            return (
              <div className={styles.shoproombox} key={i}>
                {category.map((cat: any, index: number) => {
                  return (
                    <Link
                      href={cat.href}
                      className={styles.shoproomcard}
                      key={index}
                    >
                      <div className={styles.shoproomimg}>
                        <WsStaticImage
                          src={cat.imageSrc}
                          alt={cat.alt ?? 'poster'}
                          width={192}
                          height={163}
                          loading="lazy"
                          quality={75}
                        />
                      </div>
                      <div className={styles.shoproomtext}>
                        <p className={styles.categoryname}>{cat.name}</p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </div>
      </section>
      {/* <Offer isMobile={true} items={offerData} /> */}
    </>
  );
};

export default ShopRoomsMobile;
