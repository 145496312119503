import React, { useState } from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Rating } from 'react-simple-star-rating';

const WsModal = dynamic(() => import('@/components/common/WsModal'));

interface HappyWordsProps {
  data?: any;
  title?: string;
  lead?: string;
  slides?: any;
  items?: any;
}

interface CustomerReviewProps {
  review: any;
  onClick: (videoId: string) => void;
}

const Story: React.FC<CustomerReviewProps> = ({ review, onClick }) => {
  return (
    <div
      className={styles['happyword-card']}
      onClick={() => onClick(review.video)}
    >
      <div className={styles['happyword-img']}>
        <figure>
          <Image
            width={345}
            height={260}
            quality={75}
            src={review.image}
            alt={review?.alt}
            title={review?.title}
          />
        </figure>
      </div>
      <div className={styles['happyword-text']}>
        <p> {review.testimonial}</p>
        <div className={styles.rating}>
          <Rating
            initialValue={Math.round(review.rating)}
            allowFraction={true}
            readonly
            size={23}
            fillColor={'#ee7120'}
            SVGstorkeWidth={1}
            SVGstrokeColor={'currentColor'}
            iconsCount={Math.round(review.rating)}
            emptyColor={'#D1D1D1'}
          />
        </div>
        <div className={styles['customer-name']}>
          {review.userInfo} <span>{review.city}</span>
        </div>
      </div>
    </div>
  );
};

const HappyWords: React.FC<HappyWordsProps> = ({ items }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoId, setVideoId] = useState('');
  const handleOpenModal = (videoId: string) => {
    setIsModalOpen(true);
    setVideoId(videoId);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setVideoId('');
  };
  return (
    <>
      <section className={styles['happyword-section']}>
        <div className={styles['happyword-inner']}>
          <div className="home-container-1600">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles['happyword-heading']}>
                  <div className={styles.headingNew}>{items?.title}</div>
                  <p>{items?.description}</p>
                </div>
              </div>
            </div>
            <div className={styles['happyword-content']}>
              <div className="happywordslider">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={12}
                  navigation={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 12,
                    },
                    1300: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {items?.items?.map((review: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Story review={review} onClick={handleOpenModal} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className={styles.viewallbtn}>
              <Link
                href={items.viewMore}
                className={`${styles['viewall-link']} viewall-link`}
                target="_blank"
              >
                View all
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.10059 3.8772L11.1006 8.87454L6.10059 13.8719"
                    stroke="#515151"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <WsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        mainClass="happy-customers-modal"
        size="small"
      >
        {videoId && (
          <iframe
            className={`${styles['happy-customer-video']} customer-video`}
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        )}
      </WsModal>
    </>
  );
};

export default HappyWords;
