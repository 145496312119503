import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';

const DeliveryMobile: React.FC = () => {
  const data = {
    component: 'delivery',
    items: [
      {
        title: 'Free Delivery',
        url: '/images/home-new1/liveryimg.svg',
      },
      {
        title: '36 Months Warranty*',
        url: '/images/home-new1/warranty.svg',
      },
      {
        title: 'No Cost EMI* Available',
        url: '/images/home-new1/emi-img.svg',
      },
      {
        title: '100+ Stores',
        url: '/images/home-new1/store.svg',
      },
    ],
  };
  const getDeliveryTitle = (title: string) => {
    const data = title.split(' ');
    const lastIndex = title.lastIndexOf(' ');
    const lineOne = title.substring(0, lastIndex);
    const lineTwo = data[data.length - 1];
    return { title: lineOne, subtitle: lineTwo };
  };
  return (
    <>
      <section className={styles['emi-section']}>
        <div className={styles['emi-inner']}>
          <div className="wdRow wdgx-1">
            {data?.items?.map((delivery: any, i: number) => (
              <div className="wdCol-3" key={i}>
                <div className={styles['emi-card']}>
                  <div className={styles['emi-card-img']}>
                    <Image
                      src={delivery.url}
                      alt="Emi Installation"
                      fill={true}
                      quality={100}
                      priority
                      unoptimized={false}
                    />
                  </div>
                  <p className={styles['emi-card-text']}>
                    {getDeliveryTitle(delivery.title).title}
                    <br />
                    {getDeliveryTitle(delivery.title).subtitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default DeliveryMobile;
