import React from 'react';
import styles from './style.module.scss';
import WsStaticImage from '@/components/common/WsStaticImage';
import Link from 'next/link';

interface HomeTextProps {
  items: any;
}

const HomeText: React.FC<HomeTextProps> = ({ items }) => {
  return (
    <>
      <section className={`${styles['news-section']}`}>
        <div className="container-1240 px-sm-0">
          <div className={`${styles['news-inner']}`}>
            <div className={`${styles['wdRow']} wdRow`}>
              <div className="wdCol-lg-4 wdCol-md-4">
                <p className={styles['headingNew']}>
                  {items.title}
                  <span className={styles['subheading']}>
                    {items.description}
                  </span>
                </p>
                <div className={styles['media-images']}>
                  <WsStaticImage
                    src={items.image}
                    alt="Media Partner"
                    width={384}
                    height={105}
                    quality={100}
                    loading="lazy"
                    sizes="100vw"
                  />
                </div>
                <div className={`${styles['news-button']}`}>
                  <Link href={items?.viewMore} className={styles['view-link']}>
                    View All Media
                  </Link>
                </div>
              </div>
              <div className={`wdCol-lg-8 wdCol-md-8`}>
                <div className={`${styles['news-right-card']}`}>
                  {items?.items?.map((item: any, index: number) => (
                    <Link
                      key={index}
                      className={styles['media-block']}
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <figure>
                        <WsStaticImage
                          src={item.image}
                          alt={item.alt}
                          width={425}
                          height={240}
                          loading="lazy"
                        />
                      </figure>
                      <p className={styles['media-head']}>{item.title}</p>
                      <p className={styles['media-subhead']}>{item.label}</p>
                    </Link>
                  ))}
                </div>
                <div className={styles.viewmorebtndiv}>
                  <Link
                    href={items.viewMore}
                    className={`${styles['view-all-furni']} btn view-all-btn`}
                  >
                    View All Media
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeText;
